<template>
  <div class="coupon">
    <my-nav>
      <my-nav-item
        v-for="(item, index) in nav"
        :key="item.name"
        :name="item.name"
        :needLine="true"
        :active="index == choose"
        @click.native="changeClass(index)"
      >
      </my-nav-item>
    </my-nav>

    <div class="couponList">
      <div class="item" v-for="item in coupon.list" :key="item.code">
        <discount-item :listData="item" :isOpenBtn="true" :noUse="nav[choose].type == 'used' || nav[choose].type == 'expire'"></discount-item>
      </div>
    </div>

    <div class="noCoupon" v-if="list.length == 0">
      <empty :decripe="$t('Cart.Checkout.Pages.NoValidCoupon')" :isloading="isloading"></empty>
    </div>

    <!-- 分页 -->
    <div class="pagination">
      <pagination
        :totalPage="coupon.paginated ? coupon.paginated.pages : 1"
        v-model="currentPage"
        @changePage="changePage"
      ></pagination>
    </div>
  </div>
</template>
<script>
import { MyNav, MyNavItem } from "common/mynav";
import DiscountItem from "content/discountItem/DiscountItem";
import User from "@/model/User.js";
import Pagination from "common/pagination/Pagination";
import Empty from "common/empty/Empty";
export default {
  name: "MemberCoupon",
  data() {
    return {
      nav: [
        { name:this.$t('Cart.Checkout.Pages.CouponAvailable'), type: "active" },
        { name: this.$t('Cart.Checkout.Pages.CouponUsed'), type: "used" },
        { name: this.$t('Cart.Checkout.Pages.ExpiredCoupon'), type: "expire" },
      ],
      choose: 0,
      coupon: {},
      list:[],
      currentPage: 1,
      isloading: false,
    };
  },
  created() {
    this.getCouponList();
  },
  methods: {
    //切换优惠劵类别
    changeClass(index) {
      this.choose = index;
      this.currentPage = 1;
      this.getCouponList();
    },

    //切换页码
    changePage(page) {
      this.getCouponList();
    },

    //获取优惠劵列表
    getCouponList() {
      this.isloading = true;
      this.coupon = {}
      this.list=[]
      User.Coupon.List({
        data: {
          page: this.currentPage,
          limit: 10,
          type: this.nav[this.choose].type,
        },
        succ: (res) => {
          this.coupon = res;
          this.list = res.list
          this.isloading = false;
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
          this.isloading = false;
        },
        
      });
    },
  },
  components: {
    MyNav,
    MyNavItem,
    DiscountItem,
    Pagination,
    Empty,
  },
};
</script>
<style lang='scss' scoped>
.coupon {
  background-color: white;
  padding: 1rem;
  box-sizing: border-box;

  .couponList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    .item {
      width: 25%;
      padding: 0.5rem;
      box-sizing: border-box;
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}
</style>